












































import Vue from "vue";
import { Component } from "vue-property-decorator";
import Loader from "@/helpers/loader";
import Notifier from "@/helpers/notifier";
import RejectVM, { RejectTo } from "@/models/reject";
import agent from "@/helpers/agent";
import uid from 'quasar/src/utils/uid.js';;
import { NIL as NIL_UUID } from "uuid";
import { namespace } from "vuex-class";

const app = namespace("app");

@Component
export default class Profile extends Vue {
  @app.Getter role!: string;

  shape = "";
  rejectVM: RejectVM = {
    id: "",
    comment: "",
  };
  rejectToOptions = [
    {
      label: "Commercial Banks",
      value: RejectTo.CommercialBank,
    },
    {
      label: "Reviewers",
      value: RejectTo.Reviewers,
    },
  ];
  entityTypes = [
    {
      label: "Sole Trader",
      value: 0,
    },
    {
      label: "Company",
      value: 1,
    },
  ];
  get isCommercialBankUser(): boolean {
    return ["User", "Manager"].includes(this.role);
  }
  onSubmit() {
    Loader.showLoader();
    this.rejectVM.id = this.$route.query.id as string;
    agent.Applications.reject(this.rejectVM)
      .then(() => {
        Notifier.showPositive(`You have succesfully rejected the application`);
        this.$router.push({
          name: "ListApplication",
          query: { id: this.rejectVM.id },
        });
      })
      .finally(() => [Loader.hideLoader()]);
    return;
  }
  async mounted() {
    if (this.$route.query.id) {
      agent.Applications.getRejectionComments(
        this.$route.query.id as string
      ).then((vm) => {
        if (vm.id != NIL_UUID) this.rejectVM = vm;
      });
    }
  }
}
